import React from "react";

class Search extends React.Component {
  state = { term: "" };
  render() {
    return (
      <span className="input input--search">
        <input
          className="input__field input__field--search"
          type="text"
          id="input-7"
          value={this.state.term}
          onChange={this.onTermChange}
        />
        <label className="input__label input__label--search" htmlFor="input-7">
          <span
            className="input__label-content input__label-content--search"
            data-content="Search"
          >
            Search
          </span>
        </label>
        <svg
          className="graphic graphic--search"
          width="300%"
          height="100%"
          viewBox="0 0 1200 60"
          preserveAspectRatio="none"
        >
          <path d="M1200,9c0,0-305.005,0-401.001,0C733,9,675.327,4.969,598,4.969C514.994,4.969,449.336,9,400.333,9C299.666,9,0,9,0,9v43c0,0,299.666,0,400.333,0c49.002,0,114.66,3.484,197.667,3.484c77.327,0,135-3.484,200.999-3.484C894.995,52,1200,52,1200,52V9z" />
        </svg>
      </span>
    );
  }

  onTermChange = event => {
    const term = event.target.value;
    this.setState({ term });
    this.props.onTermChange(term);
  };
}

export default Search;
