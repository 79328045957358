import React from "react";

const Icon = (props) => {
    const {name} = props.icon;
    const selectedClass = props.selected ? 'selected' : '';
  return (
      <li className={`list-item js-icon-select ${selectedClass}`} onClick={() => props.onIconSelected(props.icon)}>
        <div className={`icon-display cb-icon-${name}`} />
        <p>{name}</p>
      </li>
  );
};

export default Icon;

