import React from "react";
import Install from "./Install";

const Header = () => {
  return (
    <header>
      <h4>CODEBOTS</h4>
      <div className="break" />
      <h1>
        Lightning <span className="highlight">icons</span>.
      </h1>
      <p className="cb__about">
        Lightning icons are a Codebots resource designed to help you get icons
        into your apps. This list is a reference guide to picking the icons that
        are right for you.
      </p>
	  <Install />
    </header>
  );
};

export default Header;
