import React from "react";
import Category from "./Category";

const CategoryList = (props) => {
  return (
    <div className="cb__icons-list">
        {props.categories.map(category => <Category key={category.name} category={category} selectedIcon={props.selectedIcon} onIconSelected={props.onIconSelected}/>)}
    </div>
  );
};

export default CategoryList;
