import React from "react";

class Settings extends React.Component {
  render() {
    const selectedClass = "selected";
    const beforeClass =
      "peudo-select peudo-before js-before " +
      (this.props.before ? selectedClass : "");
    const afterClass =
      "peudo-select peudo-after js-after " +
      (!this.props.before ? selectedClass : "");
    return (
      <>
        <h2>Settings</h2>
        <section className="cb__options">
          <h3>Attach to peudo className</h3>
          <div
            className={beforeClass}
            onClick={() => this.props.onBeforeAfterClicked(true)}
          >
            <span>Before</span>
          </div>
          <div
            className={afterClass}
            onClick={() => this.props.onBeforeAfterClicked(false)}
          >
            <span>After</span>
          </div>
          <div className="pretty p-default p-smooth p-bigger p-fill">
            <input
              type="checkbox"
              className="js-icon-only"
              checked={this.props.onlyIcon}
              onChange={() => this.props.onOnlyIconChange(!this.props.onlyIcon)}
            />
            <div className="state p-primary">
              <label>I want to display the icon by itself</label>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Settings;
