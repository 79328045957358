import React from "react";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Icons from "./Icons.jsx";
import Sidebar from "./sidebar/Sidebar";
import selection from '../../icons/selection.json';
import '../SCSS/styles.scss';

const categoryMap = {};
selection.icons.forEach((icon) => {
	const name = icon.properties.name;
	const category = icon.icon.tags[1];
	
	if (!category) {
		throw new Error(`Icon ${name} has no category`);
	}

	if (!categoryMap[category]) {
		categoryMap[category] = {
			name: category,
			icons: []
		};
	}
	
	categoryMap[category].icons.push({
		name,
		decimalCodepoint: icon.properties.code,
		ligature: icon.properties.ligatures
	});
});

const CATEGORIES = Object.values(categoryMap).sort((a, b) => a.name.localeCompare(b.name));

class App extends React.Component {
  state = { categories: CATEGORIES, selectedIcon: CATEGORIES[0].icons[0] };

  render() {
    return (
      <>
        <Header />
        <Icons
          categories={this.state.categories}
          selectedIcon={this.state.selectedIcon}
          onIconSelected={this.onIconSelected}
          onTermChange={this.onTermChange}
        />
        <Sidebar selectedIcon={this.state.selectedIcon} />
        <Footer />
      </>
    );
  }

  onIconSelected = icon => {
    this.setState({ selectedIcon: icon });
  };

  onTermChange = term => {
    if (term === "") {
      this.setState({ categories: CATEGORIES });
    } else {
      // Filter category/icons by name and then remove 0 icon categories
      const filteredCategories = CATEGORIES.map(cat => {
        if(cat.name.includes(term)) {
          return {...cat};
        }
        
        return {
          ...cat,
          icons: cat.icons.filter(icon => icon.name.includes(term))
        };
      }).filter(cat => cat.icons.length > 0);
      this.setState({ categories: filteredCategories });
    }
  };
}

export default App;
