import React from "react";

const Footer = () => {
  return (
    <footer>
      <p className="legals">Copyright &copy; Codebots Pty Ltd {(new Date()).getFullYear()}.</p>
    </footer>
  );
};

export default Footer;
