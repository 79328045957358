import React from "react";
import Icon from "./Icon";

const IconList = props => {
  const { selectedIcon } = props;
  const icons = props.icons.map(icon => (
    <Icon
      key={icon.name}
      icon={icon}
      selected={selectedIcon && selectedIcon.name === icon.name}
      onIconSelected={props.onIconSelected}
    />
  ));
  return (
    <div className="cb__icons-list-items">
      <ul className="icons-list">{icons}</ul>
    </div>
  );
};

export default IconList;
