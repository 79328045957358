import React from "react";
import copy from "copy-to-clipboard";

const Code = (props) => {
	const iconOnly = props.onlyIcon ? "\n@include icon-only;" : "";
	const psuedoClass = props.before ? "before" : "after";
	const htmlCode = `cb-icon-${props.selectedIcon.name}`;

	const scss = `@include icon(${psuedoClass}, ${props.selectedIcon.name}, true);${iconOnly}`;
	const css = `font-family: lightning-icons;\n` + 
				`\n/* Unicode character */\n` +
				`content: "\\${props.selectedIcon.decimalCodepoint.toString(16)}";\n` +
				`\n/* Or ligature */\n` +
				`content: "${props.selectedIcon.ligature}";`;

	return (
		<section className="cb__code">
			<h3>The code</h3>
			<div className="break-left" />
			<p>Pick one</p>
			<div className="break-right" />
			<article className="cb__html">
				<label htmlFor="cb__html-code">HTML Class</label>
				<p>Add this class to your element</p>
				<pre className="code-block" id="cb__html-code">
					{htmlCode}
				</pre>
				<button
					className="js-copy-html copy btn btn--secondary btn--icon icon btn--sm"
					onClick={() => copy(htmlCode)}
				/>
			</article>
			<article className="cb__css">
				<label htmlFor="cb__html-code">CSS Content</label>
				<p>Add this content to your element</p>
				<pre className="code-block" id="cb__css-code">
					{css}
				</pre>
				<button
					className="js-copy-css copy btn btn--secondary btn--icon icon btn--sm"
					onClick={() => copy(css)}
				/>
			</article>
			<article className="cb__scss">
				<label htmlFor="cb__html-code">SCSS Mixin</label>
				<p>Add this include to your element</p>
				<pre className="code-block" id="cb__scss-code">
					{scss}
				</pre>
				<button
					className="js-copy-scss copy btn btn--secondary btn--icon icon btn--sm"
					onClick={() => copy(scss)}
				/>
			</article>
		</section>
	);
};

export default Code;
