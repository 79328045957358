import React from "react";
import Search from "./Search";
import CategoryList from "./CategoryList";

class Icons extends React.Component {

  render() {
    return (
      <main>
        <h2>The icons</h2>
        <section className="cb__icons">
          <Search onTermChange={this.props.onTermChange} />
          <CategoryList categories={this.props.categories} selectedIcon={this.props.selectedIcon} onIconSelected={this.props.onIconSelected} />
        </section>
      </main>
    );
  }
}

export default Icons;
