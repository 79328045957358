import React from "react";
import Settings from "./Settings";
import Code from "./Code";

class Sidebar extends React.Component {
  state = { before: true, onlyIcon: false };
  render() {
    return (
      <aside>
        <div className="sticky">
          <Settings
            before={this.state.before}
            onlyIcon={this.state.onlyIcon}
            onBeforeAfterClicked={this.onBeforeAfterClicked}
            onOnlyIconChange={this.onOnlyIconChange}
          />
          <Code
            selectedIcon={this.props.selectedIcon}
            before={this.state.before}
            onlyIcon={this.state.onlyIcon}
          />
        </div>
      </aside>
    );
  }

  onBeforeAfterClicked = before => {
    this.setState({ before });
  };

  onOnlyIconChange = onlyIcon => {
    this.setState({ onlyIcon });
  };
}

export default Sidebar;
