import React from "react";
import copy from "copy-to-clipboard";

const Install = () => {
  const scss =
    "@import url('https://cdn.codebots.com/lampbot/lightning-icons/latest/lightning-icons.scss');";
  const css =
    "@import url('https://cdn.codebots.com/lampbot/lightning-icons/latest/lightning-icons.css');";
  return (
    <section className="cb__install">
      <h2>Install icons</h2>
      <p>
        You can use an older version of the icons by replacing the latest folder
        with a version number.
      </p>
      <article className="cb__main-scss">
        <label htmlFor="cb__main-code">SCSS</label>
        <pre className="code-block" id="cb__main-code">
          {scss}
        </pre>
        <button className="js-copy-main copy btn btn--secondary btn--icon icon btn--sm" onClick={() => copy(scss)} />
      </article>
      <article className="cb__variables">
        <label htmlFor="cb__varaibles-code">CSS</label>
        <pre className="code-block" id="cb__variables-code">
          {css}
        </pre>
        <button className="js-copy-scss copy btn btn--secondary btn--icon icon btn--sm" onClick={() => copy(css)} />
      </article>
    </section>
  );
};

export default Install;
