import React from "react";
import IconList from "./IconList";

class Category extends React.Component {
  state = { expanded: true };
  render() {
    const { name, icons } = this.props.category;
    const expandClass = this.state.expanded
      ? "category-view"
      : "category-minimised";
    return (
      <article className="icon-category open js-category" id="test">
        <h4>{name}</h4>
        <div className="break" />
        <div
          className={expandClass}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        />
        {this.state.expanded && (
          <IconList
            icons={icons}
            selectedIcon={this.props.selectedIcon}
            onIconSelected={this.props.onIconSelected}
          />
        )}
      </article>
    );
  }
}

export default Category;
